import { useContext } from "react";
import { AppContext } from "../App";

function Course({ course, indent = 0 }) {
	const { category, setCategory, setMenuVisible, getTranslatedString } = useContext(AppContext);

	return (
		<>
			<div className="macrocourse-name">{getTranslatedString(course, "name")}</div>
			{course.categories.map((c, n) => (
				<button
					key={n}
					onClick={() => {
						setCategory(c);
						setMenuVisible(false);
					}}
					className={category?.id == c.id ? "active" : ""}
				>
					{getTranslatedString(c, "name")}
				</button>
			))}
		</>
	);
}

export default Course;
