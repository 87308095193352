import React, { useEffect, useState } from "react";
import Api from "./components/api/api";
import Categories from "./components/Categories";
import Shops from "./components/Shops";
import Lists from "./components/Lists";
import Menu from "./components/Menu";
import Product from "./components/Product";
import Label from "./components/Label";
import Header from "./components/Header";

import "bootstrap/dist/css/bootstrap.css";
import "./css/App.css";
import "./css/header.css";
import "./css/menuCategories2.css";
import "./css/Home.css";
import "./css/Search.css";
import Search from "./components/Search";

export const AppContext = React.createContext();
export const ShopContext = React.createContext();
export const ListContext = React.createContext();
export const ModeContext = React.createContext();

function App() {
	const [splash, setSplash] = useState(true);
	const [loading, setLoading] = useState(false);
	const [shop, setShop] = useState(false);
	const [brand, setBrand] = useState(false);
	const [list, setList] = useState(false);
	const [lists, setLists] = useState([]);
	const [settings, setSettings] = useState(false);
	const [mode, setMode] = useState("horizontal");
	const [shops, setShops] = useState([]);
	const [category, setCategory] = useState(false);
	const [menuVisible, setMenuVisible] = useState(false);
	const [product, setProduct] = useState(false);
	const [lockShop, setLockShop] = useState(false);
	const [lockList, setLockList] = useState(false);
	const [courses, setCourses] = useState([]);
	const [course, setCourse] = useState(false);
	const [products, setProducts] = useState([]);
	const [menu, setMenu] = useState([]);
	const [labels, setLabels] = useState([]);
	const [slides, setSlides] = useState([]);
	const [searchVisible, setSearchVisible] = useState(false);
	const [lang, setLang] = useState(
		localStorage.getItem("menu-lang") ? localStorage.getItem("menu-lang") : "it"
	);

	useEffect(() => {
		init();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (settings) {
			applyColors();
			applyCss();
		}
	}, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (brand) applyBrandColors();
	}, [brand]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (lang) localStorage.setItem("menu-lang", lang);
	}, [lang]);

	async function init() {
		Api.setBaseUrl();
		await load_settings();
		await loadLabels();
		await check_params();
		await load_courses();
		await loadSlides();
		setSplash(false);
	}

	async function load_settings() {
		const response = await Api.post("/settings2/get/", {
			section: ["global", "menu", "menu_api"],
		});
		if (response.data) setSettings(response.data);
	}

	const loadLabels = async () => {
		console.log("loadLabels");
		const response = await Api.post("/labels/list/", {
			type: "menu",
		});
		setLabels(response.rows);
	};

	const loadSlides = async () => {
		console.log("loadSlides");
		const response = await Api.post("/slides/list/", {
			orderby: "position",
			src: [
				{
					name: "active",
					value: 1,
					compare: "equal",
				},
				{
					name: "menu",
					value: 1,
					compare: "equal",
				},
			],
		});
		setSlides(response.rows);
	};

	async function applyColors() {
		document.documentElement.style.setProperty("--c1", settings.menu.c1);
		document.documentElement.style.setProperty("--c1c", settings.menu.c1c);
		document.documentElement.style.setProperty("--c1o", settings.menu.c1 + "50");
		document.documentElement.style.setProperty("--c2", settings.menu.c2);
		document.documentElement.style.setProperty("--c2c", settings.menu.c2c);

		document.title = "Digital Menu | " + settings.global.name;
		var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
		link.type = "image/x-icon";
		link.rel = "shortcut icon";
		link.href = settings.menu.favicon;
		document.getElementsByTagName("head")[0].appendChild(link);
	}

	async function applyBrandColors() {
		document.documentElement.style.setProperty("--c1", brand.settings.menu.c1);
		document.documentElement.style.setProperty("--c1c", brand.settings.menu.c1c);
		document.documentElement.style.setProperty("--c2", brand.settings.menu.c2);
		document.documentElement.style.setProperty("--c2c", brand.settings.menu.c2c);
	}

	async function check_params() {
		const queryParams = new URLSearchParams(window.location.search);
		const shopID = queryParams.get("shop_id");
		const brandID = queryParams.get("brand_id");
		const listID = queryParams.get("list_id");
		console.log("shop_id", shopID);
		console.log("brand_id", brandID);
		console.log("list_id", listID);
		if (shopID) {
			const response_shop = await Api.post("/shops/get/" + shopID + "/");
			setShop(response_shop.data);
			setLockShop(true);
		}
		if (brandID) {
			const response_brand = await Api.post("/brands/get/" + brandID + "/");
			setBrand(response_brand.data);
		}
		if (listID) {
			const response_list = await Api.post("/lists/get/" + listID + "/");
			setList(response_list.data);
			setLockList(true);
		}
	}

	const load_courses = async () => {
		console.log("load_courses");
		setLoading(true);
		const response = await Api.post("/menu/courses/");
		setLoading(false);
		setMenu(response.rows);
	};

	const applyCss = () => {
		console.log("applyCss");

		if (settings.menu) {
			var injectedStyles = document.createElement("style");
			injectedStyles.setAttribute("type", "text/css");
			injectedStyles.innerHTML = settings.menu.css;
			document.head.appendChild(injectedStyles);
		}
	};

	const getTranslatedString = (obj, name) => {
		if (settings.mandant.multilanguage == 0) return obj[name];
		if (obj["translations"] && !obj["translations"][lang]) return obj[name];
		if (obj["translations"] && obj["translations"][lang][name])
			return obj["translations"][lang][name];
		return obj[name];
	};

	return (
		<AppContext.Provider
			value={{
				loading,
				setLoading,
				settings,
				setSettings,
				brand,
				setBrand,
				shop,
				setShop,
				shops,
				setShops,
				list,
				setList,
				mode,
				setMode,
				category,
				setCategory,
				menuVisible,
				setMenuVisible,
				product,
				setProduct,
				lockShop,
				setLockShop,
				lockList,
				setLockList,
				courses,
				setCourses,
				course,
				setCourse,
				products,
				setProducts,
				menu,
				labels,
				lists,
				setLists,
				slides,
				setSearchVisible,
				lang,
				setLang,
				getTranslatedString,
			}}
		>
			{!splash ? (
				<>
					<Header />
					{!shop && <Shops />}
					{shop && !list && <Lists />}
					{shop && list && <Categories />}
					{searchVisible && <Search />}
					{product && <Product />}
					{menuVisible && <Menu />}
					{loading && (
						<div className="loading">
							<div className="content">
								<i className="fas fa-circle-notch fa-spin"></i>
							</div>
						</div>
					)}
					<div className="footer">
						{shop && list && (
							<div className="footer-buttons">
								{shops.length > 0 && (
									<button
										onClick={() => {
											setShop(false);
											setList(false);
										}}
									>
										<i className="fas fa-map-marker-alt"></i>
										<div>{shop.name}</div>
									</button>
								)}
								{lists && lists.length > 0 && (
									<button
										onClick={() => {
											setList(false);
											setCourse(false);
											setCategory(false);
											setProducts([]);
										}}
									>
										<i className="fas fa-list"></i>
										<div>
											{list !== "none" ? (
												getTranslatedString(list, "name")
											) : (
												<Label number={12} />
											)}
										</div>
									</button>
								)}
							</div>
						)}
						<div className="footer-content">
							<div>
								<Label number={1} />
							</div>
							<a href="https://www.yellgo.it" target="_blank">
								Powered by YellGo
							</a>
						</div>
					</div>
				</>
			) : (
				<div className="lock">Loading...</div>
			)}
		</AppContext.Provider>
	);
}

export default App;
