import React, { useContext, useEffect } from "react";

import { AppContext } from "../App";
import Products from "./Products";
import ProductsOnePage from "./ProductsOnePage";
import Home from "./Home";

function Categories() {
	const {
		settings,
		shop,
		list,
		category,
		setCategory,
		products,
		setProducts,
		courses,
		setCourses,
		menu,
		slides,
		getTranslatedString,
	} = useContext(AppContext);

	useEffect(() => {
		if (menu) filterMenu();
	}, [list, shop, menu, settings]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		console.log("category set to", category);
		if (category && (category.scroll || settings.menu.categories_menu_type != 2)) {
			const element = document.getElementById("category-" + category.id);
			if (element) element.scrollIntoView({ behavior: "smooth" });
		}
		setProducts(category.products);
	}, [category]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		console.log("menu set to", menu);
	}, [menu]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		console.log("courses set to", courses);
		if (slides.length == 0 && courses && courses.length > 0)
			setCategory(courses[0].categories[0]);
	}, [courses]); // eslint-disable-line react-hooks/exhaustive-deps

	function filterMenu() {
		console.log("filterMenu");
		let res = [];
		for (const course of menu) {
			const cr = structuredClone(course);
			cr.categories = [];
			for (const category of course.categories) {
				const ct = structuredClone(category);
				if (shop && list != "none") {
					ct.products = [];
					for (const product of category.products) {
						let pr = filterVariants(product);
						const priceList = product.prices.filter((el) => {
							return el.list_id == list.id && el.price;
						});
						const assortment = product.assortment.filter((el) => {
							return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
						});
						if (
							priceList.length > 0 &&
							assortment.length > 0 &&
							checkIngredientsAssortment(product)
						) {
							pr.price = priceList[0].price;
							ct.products.push(pr);
						}
					}
					if (ct.products.length > 0) cr.categories.push(ct);
				} else if (shop && list == "none") {
					ct.products = [];
					for (const product of category.products) {
						let pr = filterVariants(product);
						if (checkIngredientsAssortment(product)) {
							ct.products.push(pr);
						}
					}
					if (ct.products.length > 0) cr.categories.push(ct);
				} else {
					if (category.products.length > 0) cr.categories.push(category);
				}
			}
			if (cr.categories.length > 0) res.push(cr);
		}
		setCourses(res);
	}

	const filterVariants = (product) => {
		let p = JSON.parse(JSON.stringify(product));
		p.variants_categories = [];
		for (const variants_category of product.variants_categories) {
			let vc = JSON.parse(JSON.stringify(variants_category));
			vc.variants = [];
			vc.variants2 = [];
			vc.products = [];
			for (const variant of variants_category.variants) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && list != "none") {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == list.id && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
					});
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants.push(v);
					}
				} else {
					vc.variants.push(v);
				}
			}
			for (const variant of variants_category.variants2) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && list != "none") {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == list.id && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
					});
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants2.push(v);
					}
				} else {
					vc.variants2.push(v);
				}
			}
			for (const subproduct of variants_category.products) {
				let sp = JSON.parse(JSON.stringify(subproduct));
				if (shop && list != "none") {
					const priceList = subproduct.prices.filter((el) => {
						return el.list_id == list.id && el.price !== null;
					});
					const assortment = subproduct.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
					});
					if (
						priceList.length > 0 &&
						assortment.length > 0 &&
						checkIngredientsAssortment(subproduct)
					) {
						sp.price = priceList[0].price;
						vc.products.push(sp);
					}
				} else if (shop && list == "none") {
					if (checkIngredientsAssortment(subproduct)) {
						vc.products.push(sp);
					}
				} else {
					vc.products.push(sp);
				}
			}
			if (vc.variants.length > 0 || vc.products.length > 0) p.variants_categories.push(vc);
		}
		return p;
	};

	const checkIngredientsAssortment = (product) => {
		if (!settings.menu_api) return true;
		if (!settings.menu_api.enable_ingredients_assortment) return true;
		if (settings.menu_api.enable_ingredients_assortment != 1) return true;
		if (!product.ingredients) return true;
		if (product.ingredients.length == 0) return true;
		for (const ingredient of product.ingredients) {
			const assortment = ingredient.assortment.filter((el) => {
				return el.shop_id == shop.id && el.active == 1;
			});
			if (assortment.length == 0) return false;
		}
		return true;
	};

	return (
		<>
			{shop && list && settings.menu.categories_menu_type == 2 && (
				<div className="menu-categories-2">
					<div className="scroll-content">
						{courses &&
							courses.map((course, n) =>
								course.categories.map((listCategory, i) => (
									<button
										key={i}
										onClick={() => {
											if (
												settings.menu.categories_menu_type == 2 &&
												settings.menu.products_loading_type == "onepage"
											) {
												if (category) {
													const element = document.getElementById(
														"category-" + listCategory.id
													);
													if (element)
														element.scrollIntoView({
															behavior: "smooth",
														});
												} else {
													setCategory({ ...listCategory, scroll: true });
												}
											} else {
												setCategory(listCategory);
											}
										}}
										className={listCategory.id == category.id ? "active" : ""}
									>
										{getTranslatedString(listCategory, "name")}
									</button>
								))
							)}
					</div>
				</div>
			)}
			{shop && list && !category && <Home />}
			{shop && list && category && (
				<div id="main" className="main">
					{settings.menu.products_loading_type == "onepage" ? (
						<ProductsOnePage />
					) : (
						<Products products={products} category={category} />
					)}
				</div>
			)}
		</>
	);
}

export default Categories;
