import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { AppContext } from "../App";

import "../css/product.css";
import Tags from "./Tags";

function Products({ products, category }) {
	const { setProduct, getTranslatedString, shop } = useContext(AppContext);
	return (
		<div className="products">
			<h4 className="category-title">{getTranslatedString(category, "name")}</h4>
			<p className="category-description">{getTranslatedString(category, "description")}</p>
			{products &&
				products.map((product) => (
					<div key={product.id} className="product" onClick={() => setProduct(product)}>
						<div className="buttons-groups">
							{product.img && (
								<div className="img">
									<img src={product.img} alt={product.name} />
								</div>
							)}
							<div>
								<div className="title">{getTranslatedString(product, "name")}</div>
								<Tags product={product} />
								<div
									className="description"
									dangerouslySetInnerHTML={{
										__html: getTranslatedString(product, "description"),
									}}
								/>
							</div>
						</div>
						<div className="price">
							<NumberFormat
								value={product.price}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								suffix={shop.currency == "CHF" ? " CHF" : " €"}
								isNumericString={true}
							/>
						</div>
					</div>
				))}
		</div>
	);
}

export default Products;
